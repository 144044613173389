bcore.ui.form.on('User', {
	before_load: function (frm) {
		var update_tz_select = function (user_language) {
			frm.set_df_property("time_zone", "options", [""].concat(bcore.all_timezones));
		};

		if (!bcore.all_timezones) {
			bcore.call({
				method: "bcore.core.doctype.user.user.get_timezones",
				callback: function (r) {
					bcore.all_timezones = r.message.timezones;
					update_tz_select();
				}
			});
		} else {
			update_tz_select();
		}

	},

	role_profile_name: function (frm) {
		if (frm.doc.role_profile_name) {
			bcore.call({
				"method": "bcore.core.doctype.user.user.get_role_profile",
				args: {
					role_profile: frm.doc.role_profile_name
				},
				callback: function (data) {
					frm.set_value("roles", []);
					$.each(data.message || [], function (i, v) {
						var d = frm.add_child("roles");
						d.role = v.role;
					});
					frm.roles_editor.show();
				}
			});
		}
	},

	onload: function (frm) {
		frm.can_edit_roles = has_access_to_edit_user();

		if (frm.can_edit_roles && !frm.is_new()) {
			if (!frm.roles_editor) {
				var role_area = $('<div>')
					.appendTo(frm.fields_dict.roles_html.wrapper);
				frm.roles_editor = new bcore.RoleEditor(role_area, frm, frm.doc.role_profile_name ? 1 : 0);

				var module_area = $('<div>')
					.appendTo(frm.fields_dict.modules_html.wrapper);
				frm.module_editor = new bcore.ModuleEditor(frm, module_area);
			} else {
				frm.roles_editor.show();
			}
		}
	},
	refresh: async function (frm) {
		let doc = frm.doc;

		if (frm.is_new()) {
			frm.set_value("time_zone", bcore.sys_defaults.user_default_time_zone);
		}

		if (!frm.is_new() && !frm.roles_editor && frm.can_edit_roles) {
			await frm.reload_doc();
			return;
		}

		if (doc.name === bcore.session.user && !doc.__unsaved
			&& bcore.all_timezones
			&& (doc.language || bcore.boot.user.language)
			&& doc.language !== bcore.boot.user.language) {
			bcore.msgprint(__("Refreshing..."));
			window.location.reload();
		}

		frm.toggle_display(['sb1', 'sb3', 'modules_access'], false);

		if (!frm.is_new()) {
			if (has_access_to_edit_user()) {

				frm.add_custom_button(__("Resend Welcome Email"), function () {
					frm.call('send_welcome_mail_to_user').then(() => {
						bcore.msgprint(__("Email has been sent to {0}", [frm.doc.email]));
					});
				});

				frm.add_custom_button(__("Set User Permissions"), function () {
					bcore.route_options = {
						"user": doc.name
					};
					bcore.set_route('List', 'User Permission');
				}, __("Permissions"));

				frm.add_custom_button(__('View Permitted Documents'),
					() => bcore.set_route('query-report', 'Permitted Documents For User',
						{ user: frm.doc.name }), __("Permissions"));

				frm.toggle_display(['sb1', 'sb3', 'modules_access'], true);
			}

			frm.add_custom_button(__("Reset Password"), function () {
				bcore.call({
					method: "bcore.core.doctype.user.user.reset_password",
					args: {
						"user": frm.doc.name
					}
				});
			}, __("Password"));

			frm.add_custom_button(__("Reset OTP Secret"), function () {
				bcore.call({
					method: "bcore.core.doctype.user.user.reset_otp_secret",
					args: {
						"user": frm.doc.name
					}
				});
			}, __("Password"));

			frm.trigger('enabled');

			if (frm.roles_editor && frm.can_edit_roles) {
				frm.roles_editor.disable = frm.doc.role_profile_name ? 1 : 0;
				frm.roles_editor.show();
			}

			frm.module_editor && frm.module_editor.refresh();

			if (bcore.session.user == doc.name) {
				// update display settings
				if (doc.user_image) {
					bcore.boot.user_info[bcore.session.user].image = bcore.utils.get_file_link(doc.user_image);
				}
			}
		}
		if (frm.doc.user_emails) {
			var found = 0;
			for (var i = 0; i < frm.doc.user_emails.length; i++) {
				if (frm.doc.email == frm.doc.user_emails[i].email_id) {
					found = 1;
				}
			}
			if (!found) {
				frm.add_custom_button(__("Create User Email"), function () {
					frm.events.create_user_email(frm);
				});
			}
		}

		if (bcore.route_flags.unsaved === 1) {
			delete bcore.route_flags.unsaved;
			for (var i = 0; i < frm.doc.user_emails.length; i++) {
				frm.doc.user_emails[i].idx = frm.doc.user_emails[i].idx + 1;
			}
			frm.dirty();
		}

	},
	validate: function (frm) {
		if (frm.roles_editor) {
			frm.roles_editor.set_roles_in_table();
		}
	},
	enabled: function (frm) {
		var doc = frm.doc;
		if (!frm.is_new() && has_access_to_edit_user()) {
			frm.toggle_display(['sb1', 'sb3', 'modules_access'], doc.enabled);
			frm.set_df_property('enabled', 'read_only', 0);
		}

		if (bcore.session.user !== "Administrator") {
			frm.toggle_enable('email', frm.is_new());
		}
	},
	create_user_email: function (frm) {
		bcore.call({
			method: 'bcore.core.doctype.user.user.has_email_account',
			args: {
				email: frm.doc.email
			},
			callback: function (r) {
				if (!r.message) {
					bcore.route_options = {
						"email_id": frm.doc.email,
						"awaiting_password": 1,
						"enable_incoming": 1
					};
					bcore.model.with_doctype("Email Account", function (doc) {
						var doc = bcore.model.get_new_doc("Email Account");
						bcore.route_flags.linked_user = frm.doc.name;
						bcore.route_flags.delete_user_from_locals = true;
						bcore.set_route("Form", "Email Account", doc.name);
					});
				} else {
					bcore.route_flags.create_user_account = frm.doc.name;
					bcore.set_route("Form", "Email Account", r.message[0]["name"]);
				}
			}
		});
	},
	generate_keys: function (frm) {
		bcore.call({
			method: 'bcore.core.doctype.user.user.generate_keys',
			args: {
				user: frm.doc.name
			},
			callback: function (r) {
				if (r.message) {
					bcore.msgprint(__("Save API Secret: ") + r.message.api_secret);
				}
			}
		});
	},
	after_save: function(frm) {
		if (bcore.boot.time_zone && bcore.boot.time_zone.user_time_zone !== frm.doc.time_zone) {
			// Clear cache after saving to refresh the values of time_zone
			bcore.ui.toolbar.clear_cache();
		}
	}
});

function has_access_to_edit_user() {
	return has_common(bcore.user_roles, get_roles_for_editing_user());
}

function get_roles_for_editing_user() {
	return bcore.get_meta('User').permissions
		.filter(perm => perm.permlevel >= 1 && perm.write)
		.map(perm => perm.role) || ['System Manager'];
}
